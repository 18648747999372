body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  padding: 20px 0;
  background-color: #333;
  color: #fff;
}

.header h1 {
  margin: 0;
  font-size: 2rem;
}

.table-container {
  margin: 20px 0;
  text-align: center;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.table-container th, .table-container td {
  padding: 12px;
  border: 1px solid #ddd;
}

.table-container th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.section-title {
  text-align: center;
  color: #006400; /* Dark Green */
  font-size: 1.5rem;
  margin-top: 30px;
}

.download-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  text-align: center;
  background-color: #007BFF;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
}

.download-button:hover {
  background-color: #0056b3;
}

.form-container {
  margin: 30px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-container input[type="text"], .form-container input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-container button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.form-container button[type="submit"]:hover {
  background-color: #218838;
}

@media (min-width: 768px) {
  .form-container input[type="text"], .form-container input[type="file"] {
    width: 50%;
  }

  .form-container button[type="submit"] {
    width: 50%;
  }
}

.code-block {
  background-color: #f5f5f5;
  border-left: 4px solid #007BFF;
  padding: 15px;
  margin: 20px 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-x: auto;
  border-radius: 5px;
}

.code-block pre {
  margin: 0;
}

.content-container.blurred {
  filter: blur(5px);
}

.invite-code-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.invite-code-form {
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.invite-code-form input {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
}

.invite-code-form button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.invite-code-form button:hover {
  background-color: #0056b3;
}

/* Progress Bar Modal Styles */
.progress-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.progress-stage {
  flex: 1;
  text-align: center;
  padding: 10px;
  margin: 0 5px;
  border-radius: 5px;
  font-weight: bold;
  color: #007BFF;
  background-color: #e6f2ff;
  transition: all 0.3s ease;
  position: relative;
}

.progress-stage.active {
  background-color: #007BFF;
  color: white;
}

.progress-stage.done {
  background-color: #cccccc;
  color: #666666;
}

.progress-stage.done.active {
  background-color: #28a745;
  color: white;
}

.progress-stage .checkmark {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.progress-stage.done.active .checkmark {
  opacity: 1;
}